<template>
  <div class="dashboard-summary">
    <div class="pb-3">
      <PayslipSummary />
    </div>

    <div class="d-flex justify-content-end pb-3 pt-5">
      <div class="m-width-300 mr-2">
        <SearchBox
          bg-color="#D6DDE4"
          :rounded="5"
          placeholder="Search users by name, type..."
        />
      </div>

      <div>
        <FilterDropdown variant="faint-blue" :filters="filters" @filtered="setFilter" />
      </div>

      <div class="ml-3">
        <b-button block variant="link" class="btn btn-tertiary text-decoration-none px-3">
          Change Bank
        </b-button>
      </div>
    </div>

    <div class="billing-table">
      <b-table responsive :borderless="borderless" :items="items">
        <template #cell(status)="data">
          <span :class="getVariant(data.item.status)">{{ data.item.status }}</span>
        </template>
        <template #cell(details)="data">
          <router-link
            class="m-fs-12 text-tertiary"
            :to="{ name: 'billing-overview', params: { id: data.item.details } }"
          >
            View Details
          </router-link>
        </template>
        <template #head(details)="data" class="">
          <span class="d-none">{{ data.label }}</span>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import PayslipSummary from "../../components/billing/PayslipSummary.vue";
import SearchBox from "../../components/SearchBox.vue";
import FilterDropdown from "../../components/FilterDropdown.vue";

export default {
  components: {
    PayslipSummary,
    SearchBox,
    FilterDropdown,
  },
  data() {
    return {
      filterValue: "weekly",
      fields: ["date", "bank", "amount_earned", "status"],
      borderless: true,
      statuses: {
        Received: "text-success",
        Pending: "text-warning",
      },
      links: {
        ViewDetails: "/",
      },
      filters: [
        {
          name: "Today",
          value: "today",
        },
        {
          name: "Weekly",
          value: "weekly",
        },
        {
          name: "Monthly",
          value: "monthly",
        },
      ],
      items: [
        {
          date: "10 Oct, 2021",
          bank: "GTBank",
          amount_earned: "$ 3000",
          status: "Pending",
          details: "ViewDetails",
        },
        {
          date: "10 Oct, 2021",
          bank: "GTBank",
          amount_earned: "$ 3000",
          status: "Pending",
          details: "ViewDetails",
        },
        {
          date: "10 Oct, 2021",
          bank: "GTBank",
          amount_earned: "$ 3000",
          status: "Received",
          details: "ViewDetails",
        },
        {
          date: "10 Oct, 2021",
          bank: "GTBank",
          amount_earned: "$ 3000",
          status: "Received",
          details: "ViewDetails",
        },
        {
          date: "10 Oct, 2021",
          bank: "GTBank",
          amount_earned: "$ 3000",
          status: "Received",
          details: "ViewDetails",
        },
      ],
    };
  },
  methods: {
    setFilter(value) {
      console.log(value);
    },
    getVariant(status) {
      return this.statuses[status];
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-tertiary {
  height: 40px;
}
.details__link {
  font-size: 12px;
  color: $tertiary-color;
}
</style>
