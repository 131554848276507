<template>
  <div
    class="pay-slip-card card p-2 flex-shrink-0 d-flex flex-row text-center align-items-center justify-content-center"
  >
    <span class="pay-slip-card__icon mr-2">
      <CloudLightningIcon />
    </span>
    <div class="pay-slip-card__content text-decoration-none">
      <router-link to="/">Download Pay Slip</router-link>
    </div>
  </div>
</template>

<script>
import CloudLightningIcon from "../svg/CloudLightningIcon.vue";
export default {
  name: "PayslipSummary",
  components: {
    CloudLightningIcon,
  },
};
</script>

<style lang="scss" scoped>
.pay-slip-card {
  border-radius: $size-10;
  width: 40%;
  height: 210px;

  @include media-breakpoint-down (md) {
    width: 100%;
  }

  &__content {
    font-size: 0.875rem;
    font-weight: 400;
    color: $tertiary-color;
  }
}
</style>
